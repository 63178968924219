.mensajes {
  background: $color3;
  position: relative;
  overflow: hidden;
  color: white;

  @include media-breakpoint-down(md) {
    background: black;
  }

  /*  .custom-row {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column-reverse;
    }
  } */

  .col-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .custom-box {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      padding: 3rem 1rem 0;
    }

    .h2 {
      font-size: 1.8rem;
      line-height: 1.1;
      @include media-breakpoint-down(md) {
        font-size: 1.2rem;
        line-height: 28px;
      }
    }

    .parraf {
      font-size: 1.8rem;
      line-height: 40px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .btn-link {
      text-decoration: none;
      color: white;
      font-size: 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .box-text {
    max-width: 230px;
    position: absolute;
    top: 3rem;
    left: 3rem;
    transform: rotate(-20deg);

    @include media-breakpoint-down(md) {
      top: 10rem;
      left: initial;
      max-width: 150px;
      bottom: initial;
      right: 0rem;
    }

    .parraf {
      font-size: 1.8rem;
      line-height: 1.2;
      color: white;
      opacity: 0.5;
      @include media-breakpoint-down(md) {
        font-size: 1.2rem;
      }
    }
  }

  .col-video {
    padding-right: 0;
  }

  .covervid-wrapper {
    /* background: black; */
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(90deg, #000, #0000);
      width: 40%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &::after {
      content: "";
      position: absolute;
      background: linear-gradient(160deg, #000, #0000);
      width: 120%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .covervid {
      height: 100%;
      /* @include media-breakpoint-down(md) {
        max-width: 100%;
      } */
    }
  }

  /* .ratio-4x3 {
    --bs-aspect-ratio: initial;
  }

  .ratio > * {
    @include media-breakpoint-down(md) {
      height: initial;
    }
  } */
}
