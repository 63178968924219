.header {
  /* background-color: black; */
  height: 100vh;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    /* background: rgba(lime, 0.5); */
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    background: linear-gradient(0deg, black, transparent);
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .custom-box {
    position: relative;
    z-index: 1;

    animation: backInUp;
    animation-duration: 2s;

    .image {
      background: $color3;
      border-radius: 50%;
      margin-bottom: 1rem;
      padding: 0.6rem;
      display: inline-block;
      box-shadow: 5px 4px 16px $color5;

      .cover {
        max-width: 160px;
        @include media-breakpoint-down(md) {
          max-width: 140px;
        }
      }
    }

    h1 {
      font-size: 4rem;
      color: white;
      line-height: 1;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
      @include media-breakpoint-down(md) {
        font-size: 2.8rem;
      }
    }
    h2 {
      color: white;
      font-size: 1.6rem;
      line-height: 1;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
      @include media-breakpoint-down(md) {
        font-size: 1.2rem;
      }
    }
  }
}
