@keyframes scale {
  to {
    transform: scale(1.2);
  }
}

.gallery {
  background: black;
  padding: 2rem 0 0rem;

  .box-text {
    height: 62px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      height: 36px;
    }

    .h2 {
      font-size: 5rem;
      line-height: 1.1;
      color: white;

      @include media-breakpoint-down(md) {
        font-size: 3rem;
        text-align: center;
      }
    }
  }

  .container-custom {
    .col {
      padding: 0;
    }
  }

  .main-gallery {
    /* background: black; */

    .link-item {
      position: relative;
      background: darkgray;
      overflow: hidden;

      /* &:first-child {
        border-radius: 50px 0 0;
      }
      &:last-child {
        border-radius: 0 0 50px;
      } */

      &:hover {
        .img-item {
          -ms-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
        }
      }

      .img-item {
        filter: grayscale(100%) opacity(0.6) blur(1px);
        -webkit-filter: grayscale(100%) opacity(0.6) blur(1px);
        filter: grayscale(100%) opacity(0.6) blur(1px);
        transition: filter 0.3s ease;

        position: relative;

        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;

        @include media-breakpoint-down(md) {
          height: 130px;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: inset 0 0 14px 9px #000;
          transition: -webkit-filter 0.3s ease;
          transition: filter 0.3s ease;
          transition: filter 0.3s ease, -webkit-filter 0.3s ease;
        }

        &:hover {
          &::before {
            display: none;
          }
        }

        &:hover {
          filter: none;
        }
      }

      /* &:first-child {
        .img-item {
          border-radius: 50px 0 0;
        }
      } */
      /* &:last-child {
        .img-item {
          border-radius: 0 0 50px;
        }
      } */
    }
  }
}

/* Photoswipe */
.pswp {
  &.pswp--open {
    backdrop-filter: blur(2px);
  }
}
