.footer {
  position: relative;
  padding: 4rem 0;
  background: $color4;
  color: white;

  .h3 {
    font-size: 3rem;
    line-height: 1;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
